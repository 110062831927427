@import "../../styles/colors";

.about {

  &-description {
    display: flex;
    margin: 3rem;

    h2 {
      padding: 0 1rem;
    }

    p {
      padding: 0 2rem;
      max-width: 70ch;
    }

  }

  &-skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    vertical-align: center;
  }

  &-team {
    display: flex;

    & > * {
      flex-basis: 100%;
    }

  }

}

@media screen and (max-width: 768px) {

  .about-description {
    flex-direction: column;
  }

  .about-team {
    flex-flow: row wrap;
  }
}
