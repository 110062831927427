@import url('https://fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,600,700,800,900');
@import "styles/colors";

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif;
  position: relative;
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 1.7;
  color: #262821;
  overflow-x: hidden;

}

.wrapper {
  margin-right: auto; /* 1 */
  margin-left: auto; /* 1 */

  max-width: 960px; /* 2 */

  padding-right: 10px; /* 3 */
  padding-left: 10px; /* 3 */
}

section {
  padding-bottom: 5rem;
}

h1 {
  font-weight: 800;
  position: relative;
  padding-left: 15px;
  padding-right: 20px;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 2.75rem;

  &::after {
    content: "";
    background: $red;
    border-radius: 7px;
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

  }

  span {
    color: $red;

    &:before {
      content: ' ';
    }
  }
}
