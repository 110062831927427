@import "../../styles/colors";

.services {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    max-width: 100ch;
    font-weight: 500;
    font-size: 1.1rem;
    text-align: center;
  }
}
