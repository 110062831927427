.about-card {
  margin: 0 1rem;
  padding: 1rem;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.75);
  border-radius: .4rem;

  &-photo {
    img {
      width: 100%;
      height: auto;
    }
  }

  &-description {
    h4 {
      margin: .7rem 0;
      font-weight: 700;
      font-size: 1.2rem;
    }

    p {
      text-align: justify;
    }
  }
}

@media screen and (max-width: 768px) {

  .about-card {
    flex: 0 1 100%;
    max-width: 300px;
    margin-top: 1rem;
    margin-right: auto;
    margin-left: auto;
  }
}

