@import "../../styles/colors";

.progress {
  width: 100%;

  &-label {
    margin-bottom: .4em;
  }

  &-content {
    box-sizing: content-box;
    height: 20px;
    position: relative;
    background: #d2c9c9;
    border-radius: 25px;
    padding: 10px;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

    & > span {
      display: block;
      height: 100%;
      border-radius: 20px 8px 8px 20px;
      background-color: $red;
      box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
      position: relative;
      overflow: hidden;
    }
  }


}

